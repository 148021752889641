// Import the functions you need from the SDKs you need
import {getAnalytics} from 'firebase/analytics'
import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
import {getStorage} from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB6SgxM91vgHu8ct1FtWdBxBp0C4-YnquM',
  authDomain: 'mediaplatform-1396b.firebaseapp.com',
  projectId: 'mediaplatform-1396b',
  storageBucket: 'mediaplatform-1396b.appspot.com',
  messagingSenderId: '506992858267',
  appId: '1:506992858267:web:520f4f389a74b8ec45a23e',
  measurementId: 'G-GQHDRKBR16',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getDatabase(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app)

export const getTokenvalue = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BAWNzzVS0wWWie7mfwdvlYXwoM4jqomtwi5Gi4vEIjEqHdS_gif0lRUeN52gRdyo32xG1AqYIS3c9E5uArc7WjY',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken)
        localStorage.setItem('fcm_token', currentToken)
        setTokenFound(true)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        resolve(payload)
        // console.log('Message received. ', payload)
      })
    } catch (error) {
      reject(error)
    }
  })
