import React, {Fragment, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
// import {AudioRecorder} from 'react-audio-voice-recorder'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {storage} from '../../services/firebase'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {AudioRecorder} from '../../components/AudioRecorder/Index'
import QuantityInput from '../../components/QuantityInput/Index'
import upl from '../../assets/images/upl.svg'

export const CreateOrder = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [serviceData, setServiceData] = useState<any>()
  const [data, setData] = useState<any>()
  const [attribute, setAttribute] = useState<any>()
  const [users, setUsers] = useState<any>()
  const [themes, setThemes] = useState<any>()
  const [extraData, setExtraData] = useState<any>()
  const [total, setTotal] = React.useState(0)
  const [types, setTypes] = React.useState<any>()
  const [sPrice, setPrice] = React.useState<any>()
  const [themePrice, setThemePrice] = React.useState<any>()
  //uploaded files
  const [responseUpload, setResponseUpload] = useState<any>()
  const [file, setFile] = useState<File | null>(null)
  const [uploaded, setUploaded] = useState(false)
  const [audioFile, setAudioFile] = useState<Blob | null>(null)
  const [loading, setLoading] = useState(false)

  const [quantity, setQuantity] = useState(1)

  const [formData, setFormData] = useState({
    radioOption: 'link',
    checkboxOption: 0,
  })

  const handleQuantityChange = (value: number) => {
    setQuantity(value)
  }

  const id = useParams()

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
    ApiService.OrderStepOneData({
      sub_service_id: id.id,
    })
      .then((response) => {
        // console.log(response, 'responseresponseresponse')
        setUsers(response?.data.user)
        setServiceData(response?.data.subService)
        setPrice(response?.data?.price)
        localStorage.setItem(
          'pricing_method',
          response.data.service?.pricing_method
        )
        setData(response.data)
        if (response.data.service?.pricing_method_id === 1) {
          setTypes( "application/pdf")
        } else {
          setTypes( "application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel")
        }
        // if (response.data.service?.price !== 0) {
        //   const pers = (response.data.service.price * response.data.tax) / 100
        //   setTotal(pers + parseInt(response.data.service.price))
        // }
      })
      .catch((err) => {
        console.log(err)
      })

    ApiService.getSubService(id.id)
      .then((response) => {
        // setServiceData(response.data.service)
        
        setAttribute(response.data.extraData?.languages)
        setThemes(response.data.extraData?.themes)
        setExtraData(response.data.extraData)
        localStorage.setItem(
          'pricing_method',
          response.data.service?.pricing_method
        )
        setData(response.data)
        // console.log(response.data, 'servicesData')
        // if (response.data.service.price !== 0) {
        //   const pers = (response.data.service.price * response.data.tax) / 100
        //   setTotal(pers + parseInt(response.data.service.price))
        // }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const OrderSchema = Yup.object().shape({
    message: Yup.string().min(2, 'Too Short!').required(t('massageerror')),
    upload_file_id: Yup.mixed().required('الرجاء رفع الملف'),
    link: Yup.string(),
    file: Yup.mixed(),
  })

  const NewOrder = useFormik({
    initialValues: {
      upload_file_id: '',
      att_id: '',
      language_id: '',
      message: '',
      link: '',
      file: '',
    },
    validationSchema: OrderSchema,
    onSubmit: async (values) => {
      // console.log(values, 'valuesvaluesvaluesvalues')

      let linkOrFile = 2;
      if (formData.radioOption === "link") {
        linkOrFile = 1;
      }

      ApiService.OrderStepOne({
        order_description: values.message,
        sub_service_id: id.id,
        attachment_id: responseUpload.file.id,
        option_id: values.att_id,
        voice: audioFile,
        order_duration: '',
        duration_type: '',
        quantity: quantity,
        is_urgent: '',
        platform_id: '2',
        device: 'web',
        language_id: values.language_id,
        theme_service_id: values.att_id,
        isAttachFile: formData.checkboxOption,
        link_or_file: linkOrFile,
        link: values.link,
        file: values.file
      })
        .then((response) => {
          // console.log(response, 'onSubmitonSubmit')
          localStorage.setItem('order_id', response.data.id)
          // navigate(`/create_order_step_1/${response.data.id}`)
          setTimeout(() => {
            if (response.data.is_media === true) {
              navigate(`/my_orders/${response.data.id}`)
            } else {
              navigate(`/create_order_step_1/${response.data.id}`)
            }
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      NewOrder.setFieldValue('upload_file_id', e.target.files[0])
      setFile(e.target.files[0])
      setLoading(true)
      ApiService.uploadFile({
        file: e.target.files[0],
        sub_service_id: id.id,
        language_id: NewOrder.values.language_id,
      })
        .then((response) => {
          // console.log(response, 'asdsadsadasdsa')
          setLoading(false)
          setResponseUpload(response.data)
          setUploaded(true)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const removefile = (value: any) => {
    NewOrder.values.upload_file_id = ''
    setFile(null)
    setLoading(false)
  }

  const removeAudio = (value: any) => {
    setAudioFile(null)
    setFile(null)
    setLoading(false)
    const audioElement = document.getElementById('audioPlayer')
    if (audioElement !== null && audioElement.parentNode !== null) {
      document
        .getElementsByClassName('tcon-audio-container')[0]
        .removeChild(audioElement)
    }
  }

  const addAudioElement = async (e: any) => {
    if (e) {
      setAudioFile(e.file)
      // try {
      //   // Create a File object with the provided blob
      //   const audioFile = new File([blob], e.file.name, {
      //     lastModified: new Date().getTime(),
      //     type: e.file.type,
      //   })

      //   // Upload to Firebase Storage
      //   const storageRef = ref(storage, e.file.type)
      //   const uploadTask = uploadBytes(storageRef, e.file)
      //   const snapshot = await uploadTask
      //   const downloadURL = await getDownloadURL(snapshot.ref)

      //   // Handle successful upload to Firebase Storage
      //   console.log(
      //     'File uploaded to Firebase Storage! Download URL:',
      //     downloadURL
      //   )

      //   // Upload the audio file
      //   const response = await ApiService.uploadAudioRecording({
      //     record: e.file,
      //     service_id: id.id,
      //   });

      //   console.log(response, 'File uploaded to Firebase Storage');
      //   // // Handle successful upload to your server
      //   setAudioFile(response.data.record_id);
      // } catch (error) {
      //   console.error('Error handling audio:', error)
      // }
    }
  }

  const handleChangeLogoFile = (e) => {
    const {name, value, type, checked, files} = e.target

    setFormData((prevState) => ({
      ...prevState,
      [name]:
      type === 'checkbox' ? (checked ? 1 : 0) : type === 'file' ? files[0] : value,
    }))
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb
          title={data?.name}
          subTitle={data?.main_service_name}
          newOrder={t('neworder')}
        />
        <div className="tcon-about-section py-20">
          <div className="relative isolate mx-auto xl:container">
            <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
              {data?.name}
            </h2>
            <form onSubmit={NewOrder.handleSubmit}>
              <div className="space-y-12">
                <div className="relative mt-10 rounded-xl border border-gray-900/10 p-5 pb-12 lg:p-16">
                  <div className="flex items-center justify-between">
                    <h2 className="text-start text-xl font-semibold leading-7 text-gray-900">
                      {data?.description}
                    </h2>
                    {sPrice !== '0' &&
                    sPrice !== '0.00' &&
                    sPrice !== '0.00' ? (
                      <h2 className="absolute left-0 top-0 rounded-es-xl rounded-se-xl bg-green-500/50 px-4 py-3 text-end text-2xl font-semibold leading-7 text-gray-900">
                        {/* {Math.ceil(sPrice * quantity)}  */}
                        {/* {sPrice} {t('rs')} */}
                        {data?.limit} {t('order')}
                      </h2>
                    ) : (
                      ''
                    )}
                  </div>
                  {data?.pricing_method_id !== 3
                    ? attribute?.length > 0 && (
                        <Fragment>
                          <h2 className="mt-6 text-start text-xl font-semibold leading-7 text-gray-900">
                            {t('languages')}
                          </h2>
                          <div className="tcon-languages flex w-full flex-wrap items-center gap-4">
                            {attribute &&
                              attribute.map((item: any, index: number) => (
                                <Fragment key={index}>
                                  <label className="fav-item" key={index}>
                                    <input
                                      type="radio"
                                      className="language_price"
                                      name="language_id"
                                      id={item.id}
                                      value={item.id}
                                      onChange={() =>
                                        NewOrder.setFieldValue(
                                          'language_id',
                                          item.id
                                        )
                                      }
                                      onBlur={NewOrder.handleBlur}
                                      checked={
                                        NewOrder.values.language_id === item.id
                                      }
                                      data-price={sPrice}
                                    />
                                    <span>{item.name}</span>
                                  </label>
                                </Fragment>
                              ))}
                          </div>
                        </Fragment>
                      )
                    : ''}

                  {themes?.length > 0 ? (
                    <Fragment>
                      <h2 className="text-start text-xl font-semibold leading-7 text-gray-900">
                        {t('time')}
                      </h2>
                      <div className="tcon-languages flex items-center">
                        {themes &&
                          themes.map((item: any, index: number) => (
                            <label className="fav-item" key={index}>
                              <input
                                type="radio"
                                className="language_price"
                                name="att_id"
                                id={item.id}
                                value={item.id}
                                onChange={() => {
                                  NewOrder.setFieldValue('att_id', item.id)
                                  ApiService.OrderStepOneData({
                                    sub_service_id: id.id,
                                    theme_service_id: item.id,
                                  })
                                    .then((response) => {
                                      setThemePrice(response.data.price)
                                    })
                                    .catch((err) => {
                                      console.log(err)
                                    })
                                }}
                                onBlur={NewOrder.handleBlur}
                                checked={NewOrder.values.att_id === item.id}
                                data-price={item.price}
                              />
                              <span>{item.themeOption}</span>
                              {themePrice &&
                              NewOrder.values.att_id === item.id ? (
                                <span>
                                  {themePrice} {t('rs')}
                                </span>
                              ) : null}
                            </label>
                          ))}
                      </div>
                    </Fragment>
                  ) : (
                    ''
                  )}
                  {extraData && extraData.quantity_button_status === true ? (
                    <div className="mx-auto flex py-4">
                      <h2 className="my-4 me-4 text-start text-lg font-semibold leading-7 text-gray-900">
                        {extraData.quantity_text}
                      </h2>
                      <QuantityInput
                        value={quantity}
                        onChange={handleQuantityChange}
                      />
                    </div>
                  ) : null}

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t('message')}
                      </label>
                      <div className="mt-2 h-80">
                        <textarea
                          id="message"
                          name="message"
                          onChange={NewOrder.handleChange}
                          onBlur={NewOrder.handleBlur}
                          value={NewOrder.values.message}
                          placeholder={t('message')}
                          className="block h-80 max-h-80 w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ></textarea>
                        {NewOrder.errors.message &&
                          NewOrder.touched.message && (
                            <div className="text-sm text-red-600">
                              {NewOrder.errors.message}
                            </div>
                          )}
                      </div>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                        <div className="tcon-audio-wrapper justify-center sm:justify-start lg:justify-start">
                          {/* <div className="icon_cnt">
                            <SvgIcon name="microphone" className="fill-white h-6 w-6" />
                          </div> */}
                          {/* <h3>{t('recordmessage')}</h3> */}
                          <AudioRecorder
                            label={t('recordmessage')}
                            onChange={addAudioElement}
                          />
                          {/* <AudioRecorder
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                              noiseSuppression: true,
                              echoCancellation: true,
                            }}
                            onNotAllowedOrFound={(err) => console.table(err)}
                            downloadOnSavePress={false}
                            downloadFileExtension="mp3"
                            mediaRecorderOptions={{
                              audioBitsPerSecond: 128000,
                            }}
                            showVisualizer={true}
                          /> */}
                        </div>
                        <div className="tcon-file-wrapper">
                          <div className="box_contact_type box_file">
                            <input
                              type="file"
                              className="file_sty"
                              name="upload_file_id"
                              id="upload_file_id"
                              onChange={handleFileChange}
                              accept={types}
                            />
                            <div className="ver_txt">
                              <div className="icon_cnt">
                                <img
                                  src={upl}
                                  alt=""
                                />
                              </div>
                              <h3>{t('uploadfile')}</h3>
                            </div>
                            {uploaded && (
                              <div className="uploaded">
                                <p>{responseUpload && responseUpload.msg}</p>
                              </div>
                            )}
                            {loading && <div className="loader"></div>}
                          </div>
                          {file && (
                            <div id="added_files" className="row mt-5">
                              <div className="col-12">
                                <div className="file_itm flex items-center justify-between">
                                  <div className="fle_icon me-3 flex items-center">
                                    <span>
                                      <img
                                        src={upl}
                                        alt="file icon"
                                      />
                                    </span>
                                    <div className="fle_txt ms-4">
                                      <p>
                                        <span>{file.name}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="fle_txt" onClick={removefile}>
                                    <p className="remove_item" data-index="0">
                                      <i className="fa-solid fa-trash-can"></i>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {NewOrder.errors.upload_file_id &&
                            NewOrder.touched.upload_file_id && (
                              <div className="text-sm text-red-600">
                                {NewOrder.errors.upload_file_id}
                              </div>
                            )}
                        </div>
                        {serviceData?.is_design_service === true && (
                          <div className="m-auto my-8 w-full">
                            <label className="mb-3 flex">
                              <input
                                type="checkbox"
                                className="me-3"
                                name="checkboxOption"
                                checked={formData.checkboxOption === 1}
                                onChange={handleChangeLogoFile}
                              />
                              {t('LogoFile')}
                            </label>
                            {users?.link_or_file === 0 ? (
                              formData.checkboxOption === 1 && (
                                <Fragment>
                                  <div className="my-6">
                                    <label className="me-3">
                                      <input
                                        type="radio"
                                        name="radioOption"
                                        value="link"
                                        className="me-2"
                                        checked={
                                          formData.radioOption === 'link'
                                        }
                                        onChange={handleChangeLogoFile}
                                      />
                                      {t('ShareLink')}
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        name="radioOption"
                                        value="file"
                                        className="me-2"
                                        checked={
                                          formData.radioOption === 'file'
                                        }
                                        onChange={handleChangeLogoFile}
                                      />
                                      {t('AttachFile')}
                                    </label>
                                  </div>

                                  <div>
                                    {formData.radioOption == 'file' ? (
                                      <div className="mb-3 block w-full rounded-md border-0 bg-white px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                          type="file"
                                          name="file"
                                          onChange={(e: any) => {
                                            console.log(e.target.files[0]);
                                            NewOrder.setFieldValue('file', e.target.files[0])
                                          }
                                            // NewOrder.handleChange
                                          }
                                          id="file"
                                          accept="application/pdf"
                                        />
                                        {NewOrder.errors.file &&
                                          NewOrder.touched.file && (
                                            <div className="text-sm text-red-600">
                                              {NewOrder.errors.file}
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div>
                                        <input
                                          type="text"
                                          name="link"
                                          id="link"
                                          onChange={NewOrder.handleChange}
                                          onBlur={NewOrder.handleBlur}
                                          placeholder="http://"
                                          className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        {NewOrder.errors.link &&
                                          NewOrder.touched.link && (
                                            <div className="text-sm text-red-600">
                                              {NewOrder.errors.link}
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </Fragment>
                              )
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>

                    {data?.service_guide_description && (
                      <div className="bg-white p-3 sm:col-span-3 lg:p-5">
                        <h2 className="mb-10 border-b-2 border-gray-900/10 pb-10 text-xl font-semibold leading-7 text-gray-900">
                          {t('serviceDataTitle')}
                        </h2>
                        <div
                          className="order-desc mb-10"
                          dangerouslySetInnerHTML={{
                            __html: data?.service_guide_description,
                          }}
                        ></div>
                        {data?.service_guide_file ? (
                          <Link
                            className="bg-primary_color mt-5 inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                            to={data?.service_guide_file}
                            target="_blank"
                          >
                            {t('serviceDataFile')}
                          </Link>
                        ) : (
                          ''
                        )}
                        <p className="text-sm mt-5 py-4 border-t-2 border-gray-900/10">* {data?.time}</p>
                      </div>
                    )}

                    <div className="col-span-full">
                      <button
                        type="submit"
                        className="bg-primary_color rounded-md px-24 py-5 text-sm font-semibold text-white shadow-sm"
                      >
                        {t('send')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CreateOrder
