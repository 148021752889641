import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {ApiService} from '../../services/data.service'
import {ServicesItem, ServicesTitle} from './ServicesType'
import {store} from '../../reducers'
import {useTranslation} from 'react-i18next'

export const OurServices = () => {
  const userDate = store.getState().authStoreState.isAuthenticated
  const [servicesData, setServicesData] = useState<ServicesItem>()

  const {t} = useTranslation()

  useEffect(() => {
    ApiService.Services()
      .then((response) => {
        setServicesData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <React.Fragment>
      <div id="services" className="bg-white py-10 sm:py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('services')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-24">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {servicesData &&
                servicesData.map((item: any, index: number) => (
                  <div
                    className="relative flex flex-wrap items-start rounded-lg p-5 pl-20 shadow-lg"
                    key={index}
                  >
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="absolute left-5 top-6 flex h-10 w-10 items-center justify-center rounded-lg">
                        <img src={item.mediaImage} alt={item.name} />
                      </div>
                      {item.name}
                    </dt>
                    <dd className="mt-2 min-h-[85px] text-base leading-7 text-gray-600 w-full">
                      {item.description}
                    </dd>
                    {userDate ? (
                      <Link
                        to={`services/${item.id}`}
                        state={{service: item.id}}
                        className="bg-primary_color mt-5 inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                      >
                        {t('startOrder')}
                      </Link>
                    ) : (
                      <Link
                        to={'/login'}
                        state={{service: item.id}}
                        className="bg-primary_color mt-5 inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                      >
                        {t('startOrder')}
                      </Link>
                    )}
                  </div>
                ))}
            </dl>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
