import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import HomePage from "../pages/HomePage/HomePage";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from '../pages/ContactUs/ContactUs';
import Services from '../pages/Services/Services';
import ServicesSub from '../pages/Services/ServicesSub';
import CreateOrder from '../pages/Services/CreateOrder';
import CreateOrderStepOne from '../pages/Services/CreateOrderStepOne';
import MyOrders from '../pages/MyOrders/MyOrders';
import SingleOrder from '../pages/MyOrders/SingleOrder';
import Tickets from '../pages/Tickets/Tickets';
import TicketsSingle from '../pages/Tickets/TicketsSingle';
import Chat from '../pages/Chat/Chat';
import Profile from '../pages/Profile/Profile';
import Edit from '../pages/Profile/Edit';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Terms from '../pages/Terms/Terms';
import { PageErrorElement } from '../pages/Errors/Page/Index';
import { UserGuide } from '../pages/userGuide/Index';
import CommonOrganizations from '../pages/CommonOrganizations/Index';

const AuthRoute = () => {
  return (
    <Routes>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"about-us"} element={<AboutUs />} />
        <Route path={"contact-us"} element={<ContactUs />} />
        <Route path={"login"} element={<Navigate replace to="/" />} />
        <Route path={"verify"} element={<Navigate replace to="/" />} />
        <Route path={"register"} element={<Navigate replace to="/" />} />
        <Route path={"profile"} element={<Profile />} />
        <Route path={"profile/edit"} element={<Edit />} />
        <Route path={"services"} element={<Services />} />
        <Route path={"services/:id/"} element={<ServicesSub />} />
        <Route path={"create-order/:order_id/:id/"} element={<CreateOrder />} />
        <Route path={"create_order_step_1/:id/"} element={<CreateOrderStepOne />} />
        <Route path={"my_orders"} element={<MyOrders />} />
        <Route path={"my_orders/:id/"} element={<SingleOrder />} />
        <Route path={"tickets"} element={<Tickets />} />
        <Route path={"tickets/:order_id/:id"} element={<TicketsSingle />} />
        <Route path={"chat/:order_id/:user_id"} element={<Chat />} />
        <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"terms"} element={<Terms />} />
        <Route path={"userGuide"} element={<UserGuide />} />
        <Route path={"participating-organizations"} element={<CommonOrganizations />} />
        <Route path={"*"} element={<PageErrorElement />} />
    </Routes>
  )
}

export default AuthRoute