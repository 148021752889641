import React, {useEffect, useState} from 'react'
import {ApiService} from '../../services/data.service'
import {ServicesItem} from './ServicesType'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export const Services = () => {
  const {t} = useTranslation()
  const params = useParams()
  const [servicesData, setServicesData] = useState<ServicesItem>()
  const [activeIndex, setActiveIndex] = useState(-1)

  const serviceId = params.id

  const handleClick = (index: any) => {
    setActiveIndex(index === activeIndex ? -1 : index)
  }

  const howOldIs = (item: any, parent: any) => {
    localStorage.setItem('create_order_id', parent)
    localStorage.setItem('parent_id', item)
  }

  useEffect(() => {
    ApiService.getServices()
      .then((response) => {
        setServicesData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // console.log(servicesData, 'servicesData')

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <div className="flex flex-wrap justify-between lg:flex-nowrap">
          <Breadcrumb title={t('myservices')} />
          <Link
            to={'/userGuide'}
            className="bg-primary_color mt-4 inline-block w-1/2 rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white lg:mt-0 lg:w-1/6"
          >
            {t('userGuide')}
          </Link>
        </div>
        <div className="tcon-about-section py-40">
          <div className="relative isolate mx-auto xl:container">
            <React.Fragment>
              <div id="services" className="py-15 sm:py-15 bg-white">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      {t('myservices')}
                    </h2>
                  </div>
                  <div className="mx-auto my-16 lg:mt-24">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                      {servicesData &&
                        servicesData.map((item: any, index: number) => (
                          <div
                            className="relative flex flex-wrap items-start rounded-lg p-5 pl-20 shadow-lg"
                            key={index}
                          >
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              <div className="absolute left-5 top-6 flex h-10 w-10 items-center justify-center rounded-lg">
                                <img src={item.mediaImage} alt={item.name} />
                              </div>
                              {item.name}
                            </dt>
                            <dd className="mt-2 min-h-[85px] text-base leading-7 text-gray-600 w-full">
                              {item.description}
                            </dd>
                            <Link
                              to={`/services/${item.id}`}
                              state={{service: item.id}}
                              className="bg-primary_color mt-5 inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                            >
                              {t('startOrder')}
                            </Link>
                          </div>
                        ))}
                    </dl>
                  </div>

                  {/* <div className="">
                    {servicesData &&
                      servicesData.map((item: any, index: number) => (
                        <div
                          className="services-wrapper mb-5 rounded-md bg-white px-5 py-5 shadow-lg"
                          key={index}
                        >
                          <div
                            className="mb-5 flex justify-between"
                            onClick={() => handleClick(index)}
                          >
                            <div className="services-content">
                              <h3 className="mb-3 text-lg font-bold">
                                {item.name}
                              </h3>
                              <p>{item.description}</p>
                            </div>
                            <img
                              src={item.mediaImage}
                              alt={item.name}
                              className="h-10 w-10"
                            />
                          </div>
                          {index === activeIndex &&
                            item &&
                            item.subServices.map((sub: any, index: number) => (
                              <Link
                                id={sub.id}
                                to={`${
                                  sub.limit !== sub.userServiceCount
                                    ? `/create-order/${item.id}/${sub.id}`
                                    : ``
                                }`}
                                state={{parent_id: item.id, service_id: sub.id}}
                                onClick={() => {
                                  howOldIs(sub.id, item.id)
                                }}
                                key={index}
                                className={`${
                                  sub.limit !== sub.userServiceCount
                                    ? 'active'
                                    : 'disabled'
                                }`}
                              >
                                <div
                                  className="mb-5 flex items-center justify-between rounded-md bg-gray-200 px-3 py-3 transition-all hover:bg-gray-300"
                                  key={index}
                                >
                                  <div className="services-content">
                                    <h3 className="mb-2 text-lg font-bold">
                                      {sub.name}
                                    </h3>
                                    <p>{sub.description}</p>
                                  </div>
                                  {sub.time_label && (
                                    <div className=" bg-primary_color rounded-full px-4 py-2 text-sm text-green-100">
                                      <p>{sub.time_label}</p>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            ))}
                        </div>
                      ))}
                  </div> */}
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services
