import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {useFormik} from 'formik'
import {Link, useParams} from 'react-router-dom'
import {ref, onValue, push} from 'firebase/database'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {db} from '../../services/firebase'
import FileUploadChat from './FileUploadChat'
import {useTranslation} from 'react-i18next'
import ImgDefault from "../../assets/images/icon/default.jpg"

export const Chat = () => {
  const {t} = useTranslation()
  const order_id = localStorage.getItem('order_id')
  const executor = localStorage.getItem('id_executor')

  const [newMessage, setNewMessage] = useState<any>()
  const [chats, setChats] = useState<any | null>(null)
  const [tempUuid, setTempUuid] = useState('')

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const chat_ids = useParams()

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  useEffect(() => {
    scrollToBottom()
    setTempUuid(`${chat_ids.order_id}-IDProject-${chat_ids.user_id}-IDProvider`)
    const dataId = `${chat_ids.order_id}-IDProject-${chat_ids.user_id}-IDProvider`
    onValue(ref(db, 'Chat/Orders/' + chat_ids.order_id), (snapshot) => {
      setChats([])
      const data = snapshot.val()
      if (data !== null) {
        Object.values(data).map((chat: any) => {
          setChats((oldArray: any) => [...oldArray, chat])
        })
      }
    })
  }, [])

  const getDate = () => {
    const today = new Date()
    const month = today.getMonth() + 1
    const paddedMonth = month < 10 ? '0' + month : month.toString()
    const year = today.getFullYear()
    const date = today.getDate()
    const time = moment().format('HH:mm:ss')
    return `${date}-${paddedMonth}-${year} ${time}`
  }
    
  const idUser = JSON.parse(localStorage.getItem('id_user') || '{}')
  // const executorIDs = JSON.parse(chat_ids.user_id || '{}')
  // const executorID = parseInt(executorIDs)

  const NewChat = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      setNewMessage(values.message)
      const message = values.message

      ApiService.getChat({
        value: chat_ids.order_id,
      })
        .then((response) => {
          // setTicketsData(response.data)
        })
        .catch((err) => {
          console.log(err)
        })
      setTimeout(() => {
        push(ref(db, `/Chat/Orders/${chat_ids.order_id}`), {
          providerId: chat_ids.user_id,
          seekerId: idUser,
          senderId: idUser,
          message: message,
          timeMessage: getDate(),
          type: 1,
        })
      }, 1000)

      values.message = ''

      ApiService.chatNotification({
        userId: chat_ids.user_id,
        orderId: chat_ids.order_id,
      })
        .then((response) => {
          console.log(response, 'response');
        })
        .catch((err) => {
          console.log(err)
        })

    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={'المحادثة'} />
        <div className="tcon-about-section m-auto lg:w-9/12 py-20 lg:py-10">
          <div className="rounded-md border">
            <div className="chat-header ">
              <div className="chat-head-user">
                <div className="img">
                  <img
                    id="user_image"
                    alt=""
                    src={ImgDefault}
                  />
                </div>
                <h3>
                  <span id="sender_name">مبدع #{chat_ids.user_id}</span>
                  <span className="badge bg-info" id="sender_id"></span>
                </h3>
              </div>
              <div className="">
                <Link
                  className="bg-primary_color inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                  to={`/my_orders/${chat_ids.order_id}`}
                >
                  {t('backOrder')}
                </Link>
              </div>
            </div>
            <div className="chat-body">
              {chats &&
                chats.map((item: any, index: number) => (
                  <div
                    id={item?.senderId}
                    key={index}
                    className={
                      item?.senderId === 1
                        ? 'chat-to chat-item px-10'
                        : 'chat-from chat-item px-10'
                    }
                  >
                    <div>
                      <div className="img">
                        <div>
                          <img
                            className="sender_image"
                            alt=""
                            src={ImgDefault}
                          />
                        </div>
                      </div>
                      <div>
                        {item.type === 'acceptOrder' && (
                          <div className="chat-item-text">{item.message}</div>
                        )}
                        {item.type === 1 && (
                          <div className="chat-item-text">{item.message}</div>
                        )}
                        {item.type === 2 && (
                          <div className="chat-item-text">
                            <img src={item.message} alt={item.timeMessage} />
                          </div>
                        )}
                        {item.type === 3 && (
                          <div className="chat-item-text">
                            <Link to={item.message} target="_blank">
                              <p>{item.fileName}</p>
                            </Link>
                          </div>
                        )}
                        {item.type === 4 && (
                          <div className="chat-item-text">
                            <audio controls>
                              <source src={item?.message} type="audio/mpeg" />
                            </audio>
                          </div>
                        )}
                        <div className="chat-time">{item.timeMessage}</div>
                      </div>
                    </div>
                  </div>
                ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
          {/* <form id="send_ticket" onSubmit={NewChat.handleSubmit}>
            <div className="chat-footer relative">
              <FileUploadChat />
              <div className="textare-with-btns w-full">
                <textarea
                  id="message"
                  name="message"
                  onChange={NewChat.handleChange}
                  onBlur={NewChat.handleBlur}
                  value={NewChat.values.message}
                  placeholder={t('message')}
                  className="mb-3 block h-20 w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></textarea>
              </div>
              <button
                type="submit"
                className={
                  NewChat.values.message === ''
                    ? 'text-md me-3 flex w-1/4 justify-center rounded-sm bg-lime-700 px-3.5 py-2.5 text-center font-semibold text-white disabled:opacity-75'
                    : 'text-md me-3 flex w-1/4 justify-center rounded-sm bg-lime-700 px-3.5 py-2.5 text-center font-semibold text-white'
                }
                disabled={NewChat.values.message === ''}
              >
                <span className="flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M7.11039 5.96028L16.1304 2.95028C20.1804 1.60028 22.3804 3.81028 21.0404 7.86028L18.0304 16.8803C16.0104 22.9503 12.6904 22.9503 10.6704 16.8803L9.78039 14.2003L7.10039 13.3103C1.04039 11.3003 1.04039 7.99028 7.11039 5.96028Z"
                      fill="white"
                    ></path>
                    <path
                      d="M12.1191 11.6296L15.9291 7.80957L12.1191 11.6296Z"
                      fill="white"
                    ></path>
                    <path
                      d="M12.1205 12.38C11.9305 12.38 11.7405 12.31 11.5905 12.16C11.3005 11.87 11.3005 11.39 11.5905 11.1L15.3905 7.28C15.6805 6.99 16.1605 6.99 16.4505 7.28C16.7405 7.57 16.7405 8.05 16.4505 8.34L12.6505 12.16C12.5005 12.3 12.3105 12.38 12.1205 12.38Z"
                      fill="white"
                    ></path>
                  </svg>
                  أرسل
                </span>
              </button>
            </div>
          </form> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Chat
